import React from "react"

import Button from "../../Button"
import { Form, Fieldset, FormHeading } from "../layout"
import { P, Ul, Li, FooterNote } from "../../Dialog"
import A from "../../A"

const SuggestNewServiceIntro = ({ onSubmit }) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormHeading>Requirements</FormHeading>
      <Fieldset>
        <P>
          Before you submit your service to us, please check your service meets
          the following requirements. Your service should:
        </P>
        <Ul>
          <Li>Support residents in Southampton</Li>
          <Li>
            Support families, vulnerable adults or those with special
            educational needs or disabilities (SEND)
          </Li>
          <Li>
            If applicable, be verifiable with an Ofsted or CQC registration
          </Li>
        </Ul>
        <P>
          You will also need to provide an email address so we can confirm your
          submission.
        </P>
        <P>
          Once you submit your service, our moderation team will review it and
          notify you if it's been accepted or rejected.
        </P>
      </Fieldset>
      <Button type="submit">Next</Button>
      <FooterNote>
        Read our{" "}
        <A
          href={
            "https://www.southampton.gov.uk/contact-us/privacy-cookies/privacy-policy/"
          }
          target={"_blank"}
        >
          privacy policy
        </A>{" "}
        to understand how we collect, use and store your personal data
      </FooterNote>
    </Form>
  )
}

export default SuggestNewServiceIntro
